import React from "react";
import { Link } from "react-router-dom";
import "./navbar.scss";
import { Container, Nav, Navbar } from "react-bootstrap";

function NavBar() {
  return (
    <div className="sticky">
      <div className="cus-nav py-3">
        <Container fluid>
          <Navbar variant="light" expand="lg">
            <Link style={{ textDecoration: "none" }} to="/" href="#top">
              <div className="logo">
                <img
                  className="img-fluid nav-logo"
                  src={require("../../assets/img/logopng.png").default}
                  alt=""
                />
                {/* <h2 className="text-primary font-weight-bold p-0 m-0">
                  LogoName
                </h2> */}
              </div>
            </Link>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="ml-auto">
                <Link to="/" className="link-style nav-link linkh">
                  Business services
                </Link>
                <Link to="/partners" className="link-style nav-link linkh">
                  Partner with us!
                </Link>
                <Link to="/industries" className="link-style nav-link linkh">
                  Industries We Service!
                </Link>
                {/* <Link to="/contact-us" className="link-style nav-link linkh">
                  Contact Us
                </Link> */}
                <div className="d-flex align-items-center justify-content-center font-weight-bold mx-1">
                  <a
                    style={{
                      textDecoration: "none",
                      border: "none",
                      color: "#000",
                    }}
                    className="btn btn-primary font-weight-bold mx-4"
                    href="https://docs.google.com/forms/d/e/1FAIpQLScm4YGtCHgL67UeRflw5xW_miRKcLvJV1gBsNbm3E7TcSBvHw/viewform"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Apply for funding
                  </a>{" "}
                </div>
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        </Container>
      </div>
    </div>
  );
}

export default NavBar;
