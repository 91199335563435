import React from "react";
// import { FaLinkedin, FaYoutube, FaTwitterSquare } from "react-icons/fa";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <footer
      className="text-center text-lg-start text-white"
      style={{ background: "#1c2331" }}
    >
      {/* <section
        className="d-flex justify-content-between p-1"
        id="footer"
        style={{ background: "#0356fc" }}
      >
        <div className="row w-100">
          <div className="col-sm-12 col-md-6 foot-left">
            <span
              style={{ fontSize: "1.1rem" }}
              className="mx-3 text-dark font-weight-gold"
            >
              Email us
            </span>
          </div>

          <div className="col-sm-12 col-md-6 foot-banner">
            <div>
              <a
                style={{ textDecoration: "none" }}
                href="mailto:happytodevelop@gmail.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span style={{ fontSize: "1.1rem" }} className="mx-3 text-dark">
                  happytodevelop@gmail.com
                </span>
              </a>
            </div>
          </div>
        </div>
      </section>
     */}

      <section className="pt-2">
        <div className="container text-center text-md-start mt-5">
          <div className="row">
            <div
              className="col-md-3 col-lg-4 col-xl-3 mb-3 mx-auto text-center"
              style={{ fontSize: "1.5rem" }}
            >
              <img
                style={{ height: "140px" }}
                className="img-fluid"
                src={require("../../assets/img/logo3.png").default}
                alt=""
              />
              {/* <i>
                "Revenue based Finance advances capital on future receivables.
                This gives a merchant"
              </i> */}
            </div>
            <div className="col-md-2 col-lg-2 col-xl-2 mx-auto mb-2">
              <h6 className="text-uppercase fw-bold">Navigate</h6>
              <hr
                className="mb-2 mt-0 d-inline-block mx-auto"
                style={{
                  width: "90px",
                  background: "#0356fc",
                  height: "2px",
                }}
              />
              <p>
                <Link
                  style={{ textDecoration: "none" }}
                  to="/"
                  className="text-white"
                >
                  Business services
                </Link>
              </p>
              <p>
                <Link
                  style={{ textDecoration: "none" }}
                  to="/partners"
                  className="text-white"
                >
                  Partner with us!
                </Link>
              </p>
              <p>
                <Link
                  style={{ textDecoration: "none" }}
                  to="/industries"
                  className="text-white"
                >
                  Industries We Service!
                </Link>
              </p>
            </div>

            <div
              id="support"
              className="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-2"
            >
              <h6 className="text-uppercase fw-bold orange-font">Support</h6>
              <hr
                className="mb-2 mt-0 d-inline-block mx-auto"
                style={{
                  width: "90px",
                  background: "#0356fc",
                  height: "2px",
                }}
              />
              <p>
                <a
                  style={{ textDecoration: "none" }}
                  href="mailto:info@c2advance.com"
                  className="text-white"
                  rel="noreferrer"
                >
                  Email
                </a>
              </p>
              <p>
                <a
                  style={{
                    textDecoration: "none",
                    border: "none",
                    color: "#000",
                  }}
                  className="text-white"
                  href="https://docs.google.com/forms/d/e/1FAIpQLScm4YGtCHgL67UeRflw5xW_miRKcLvJV1gBsNbm3E7TcSBvHw/viewform"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Apply for funding
                </a>
              </p>

              <p>
                <a
                  style={{
                    textDecoration: "none",
                    border: "none",
                    color: "#000",
                  }}
                  className="text-white"
                  href="https://www.bbb.org/us/fl/miami/profile/financial-services/c2-advance-llc-0633-92022754"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Check our ratings
                </a>
              </p>
            </div>

            <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mb-2">
              <div className="h-100 d-flex justify-content-center align-items-center">
                <a
                  style={{
                    textDecoration: "none",
                    border: "none",
                    color: "#000",
                  }}
                  href="https://www.bbb.org/us/fl/miami/profile/financial-services/c2-advance-llc-0633-92022754"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    className="img-fluid"
                    src={require("../../assets/img/bbb.webp").default}
                    alt=""
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div
        className="text-center p-3  orange-font"
        style={{
          background: "rgba(0, 0, 0, 0.2)",
        }}
      >
        © 2023 C2ADVANCE. All Rights Reserved
      </div>
    </footer>
  );
}

export default Footer;
