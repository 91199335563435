import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./home.scss";
import { FaIndustry } from "react-icons/fa";
import { IoBusiness, IoSpeedometer } from "react-icons/io5";
import { MdGppGood } from "react-icons/md";
import { GiReceiveMoney } from "react-icons/gi";
import Footer from "../../components/footer/Footer";

function Home() {
  return (
    <div className="homepage">
      <div className="showcase  mmt">
        <Container className=" d-flex align-items-center justify-content-center h-100">
          <Container className=" d-flex justify-content-center">
            <div className="showcasecard">
              <div className="">
                <h2 className="font-weight-bold text-center">
                  Let's get growing.
                </h2>
              </div>
              <div className="text-center d-flex justify-content-between mt-4">
                <div className="">
                  <a
                    style={{
                      textDecoration: "none",
                      border: "none",
                      color: "#000",
                    }}
                    className="btn btn-primary font-weight-bold w-100"
                    href="https://docs.google.com/forms/d/e/1FAIpQLScm4YGtCHgL67UeRflw5xW_miRKcLvJV1gBsNbm3E7TcSBvHw/viewform"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Apply for funding
                  </a>
                </div>
                <div className="">
                  <a
                    style={{
                      textDecoration: "none",
                      border: "none",
                      color: "#000",
                    }}
                    className="btn btn-primary font-weight-bold  w-100"
                    href="https://docs.google.com/forms/d/e/1FAIpQLScDOUd1oBSAQbtDJc1B3pzr_bYY4tmeaUpO4O34I9CDWN8Tig/viewform?vc=0&c=0&w=1&flr=0"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Become a Partner
                  </a>
                </div>
              </div>
            </div>
          </Container>
        </Container>
      </div>

      <Container className="info1 mmt-5">
        <div className="text-center">
          <h1 style={{ fontSize: "2rem" }} className="font-weight-bold">
            Get the alternative financing you need to run the business you want
          </h1>
          <h1
            style={{ fontSize: "3rem" }}
            className="text-primary font-weight-bold"
          >
            Fast and Affordable Business Financing
          </h1>
        </div>

        <Row className="mmt-5">
          <Col lg="6">
            <div className="">
              <div className="d-flex">
                <div className="">
                  <FaIndustry className="text-primary" size={30} />
                </div>
                <div className="h4 font-weight-bold mx-3">
                  Competitive industry rates
                </div>
              </div>
              <p className="subtitle">
                In today's competitive landscape, securing financing at
                favorable industry rates is paramount to your business's
                success. Our commitment to offering competitive industry rates
                means you can access the capital you need without breaking the
                bank. We understand that cost-effectiveness is a key factor in
                your financial decisions, and we strive to provide rates that
                not only match but often exceed industry standards. With our
                competitive rates, you can confidently invest in your business's
                future, knowing you're making the most prudent financial choices
                in a fiercely competitive market.
              </p>
            </div>
          </Col>
          <Col lg="6" className="rmt">
            <div className="">
              <div className="d-flex">
                <div className="">
                  <IoBusiness className="text-primary" size={30} />
                </div>
                <div className="h4 font-weight-bold mx-3">
                  Flexible terms to fit your business needs
                </div>
              </div>
              <p className="subtitle">
                Our financing options are designed with your unique needs in
                mind, offering flexible terms that adapt to your financial
                circumstances. Whether you require short-term capital to cover
                immediate expenses or a longer repayment schedule to accommodate
                your business growth, we can tailor our terms to align with your
                goals. We understand that one size doesn't fit all, and that's
                why we're committed to providing you with the flexibility you
                need to succeed.
              </p>
            </div>
          </Col>
        </Row>

        <Row className="mmt-5">
          <Col lg="6">
            <div className="">
              <div className="d-flex">
                <div className="">
                  <IoSpeedometer className="text-primary" size={30} />
                </div>
                <div className="h4 font-weight-bold mx-3">
                  5-minute application
                </div>
              </div>
              <p className="subtitle">
                Our streamlined application process puts your time and
                convenience at the forefront. With just a quick 5-minute
                application, you can kickstart your journey to accessing the
                funds your business needs. We value your time and understand the
                urgency that often accompanies financial decisions. By
                simplifying the application process, we aim to make securing
                financing as efficient as possible, so you can get back to what
                matters most—running and growing your business. Say goodbye to
                lengthy paperwork and hello to a hassle-free, expedited path to
                financial support with our 5-minute application.
              </p>
            </div>
          </Col>
          <Col lg="6" className="rmt">
            <div className="">
              <div className="d-flex">
                <div className="">
                  <MdGppGood className="text-primary" size={30} />
                </div>
                <div className="h4 font-weight-bold mx-3">
                  Approval in as little as 4 hours
                </div>
              </div>
              <p className="subtitle">
                Experience the advantage of rapid decision-making with our
                lightning-fast approval process, often taking as little as 4
                hours. We know that time is of the essence when it comes to
                seizing business opportunities or addressing urgent financial
                needs. Our efficient evaluation and approval system is designed
                to get you the green light in record time, so you can swiftly
                move forward with your plans. With approvals in as little as 4
                hours, you can count on us to provide the prompt support your
                business deserves, ensuring that you don't miss a beat in this
                fast-paced business world.
              </p>
            </div>
          </Col>
        </Row>

        <Row className="mmt-5">
          <Col lg="6">
            <div className="">
              <div className="d-flex">
                <div className="">
                  <GiReceiveMoney className="text-primary" size={30} />
                </div>
                <div className="h4 font-weight-bold mx-3">
                  Funding in as little as 24 hours
                </div>
              </div>
              <p className="subtitle">
                When time is of the essence, our commitment to delivering
                funding in as little as 24 hours sets us apart. We understand
                that your business can't afford to wait, whether it's seizing an
                opportunity, bridging a financial gap, or addressing unforeseen
                expenses. With our expedited funding process, you can rest
                assured that the capital you need will be in your hands within
                24 hours of approval. Our agility and dedication to quick
                turnarounds mean that you can keep your business operations
                running smoothly, ensuring you're always prepared to tackle
                whatever challenges or opportunities come your way.
              </p>
            </div>
          </Col>
        </Row>
      </Container>

      <div className="info2 mmt-5">
        <Container>
          <div className="text-center">
            <h1 className="text-primary font-weight-bold">
              Minimum Business Requirements
            </h1>
          </div>

          <Row className="mmt text-center">
            <Col lg="4">
              <div className="h4 font-weight-bold">625 credit score</div>
            </Col>
            <Col lg="4" className="rmt">
              <div className="h4 font-weight-bold">2+ years in business</div>
            </Col>
            <Col lg="4" className="rmt">
              <div className="h4 font-weight-bold">
                $250K average annual revenue
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      {/* 
      <Container className="mmt-10">
        <Row>
          <Col className="d-flex justify-content-center" lg="4">
            <div className="d-flex">
              <div className="mx-1 d-flex align-items-center">
                <AiOutlineFileDone color={"#5CE2E2"} size={80} />
              </div>
              <div className="mx-1">
                <h1 className="font-weight-bold">210+</h1>
                <p className="subtitle">Project Done</p>
              </div>
            </div>
          </Col>
          <Col className="rmt d-flex justify-content-center" lg="4">
            <div className="d-flex">
              <div className="mx-1 d-flex align-items-center">
                <MdPeopleAlt color={"#5CE2E2"} size={80} />
              </div>
              <div className="mx-1">
                <h1 className="font-weight-bold">210+</h1>
                <p className="subtitle">Happy Clients</p>
              </div>
            </div>
          </Col>
          <Col className="rmt d-flex justify-content-center" lg="4">
            <div className="d-flex">
              <div className="mx-1 d-flex align-items-center">
                <MdContactPage color={"#5CE2E2"} size={80} />
              </div>
              <div className="mx-1">
                <h1 className="font-weight-bold">200+</h1>
                <p className="subtitle">Appreciations</p>
              </div>
            </div>
          </Col>
        </Row>
      </Container> */}

      {/* <Container className="mmt-5 p-5">
        <Carousel1 />
      </Container> */}

      <Container className="mmt-5">
        <Row>
          <Col lg="6">
            <div className="">
              <img
                className="img-fluid"
                style={{ borderRadius: "1rem" }}
                src={require("../../assets/img/hbi.jpeg").default}
                alt=""
              />
            </div>
          </Col>
          <Col className="d-flex align-items-center rmt" lg="6">
            <div>
              <p className="subtitle font-weight-bold">
                Merchant Cash Advance delivers to you a lump sum for use as
                working capital in return for the purchase of a specified amount
                of future sales. It is like factoring, a well known process for
                generating cash flow for businesses, only here cash is advanced
                based on an event that has yet to happen - your anticipated
                sales based upon historical performance.
              </p>
              <p className="subtitle font-weight-bold">
                We only get paid when you make a sale. This makes the Merchant
                Cash Advance product one of the most cash flow friendly products
                available today.
              </p>
            </div>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <p className="subtitle font-weight-bold">
              This gives a merchant the opportunity for flexibility in managing
              their cash flow by accessing Capital quickly without the typical
              months long bank loan process. MCA’s are a useful resource in
              aiding the development and growth of a business. They require
              little to no credit and the funding process is one of the quickest
              in the industry.
            </p>
          </Col>
        </Row>
      </Container>

      <div className="mt-5">
        <Footer />
      </div>
    </div>
  );
}

export default Home;
