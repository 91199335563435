import React from "react";
import Footer from "../../components/footer/Footer";
import { Container, Row, Col } from "react-bootstrap";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

function Industries() {
  return (
    <div className="">
      <div className="contact-showcase mmt">
        <Container className="d-flex align-items-center justify-content-center h-100">
          <div className="">
            <div className="">
              <h1 className="text-light font-weight-bold text-center">
                INDUSTRIES
              </h1>
              {/* <p className="subtitlw">
                Explore how we think, perform and ensure client satisfaction.
              </p> */}
            </div>
          </div>
        </Container>
      </div>

      <Container className="mmt py-5">
        <Tabs
          defaultActiveKey="Blockchain"
          id="uncontrolled-tab-example"
          className="mb-3"
        >
          <Tab eventKey="Blockchain" title="Approved Services">
            <div className="">
              <Row className="mt-5">
                <Col lg="4">
                  <div className="text-center">
                    <div className="">
                      <img
                        className="img-fluid iimg"
                        src={
                          require("../../assets/img/industry/i5.jpeg").default
                        }
                        alt=""
                      />
                    </div>
                    <h3 className="mt-4 font-weight-bold">
                      Landscapping / Seasonal
                    </h3>
                  </div>
                </Col>
                <Col className="rmt" lg="4">
                  <div className="text-center">
                    <div className="">
                      <img
                        className="img-fluid iimg"
                        src={
                          require("../../assets/img/industry/i3.jpeg").default
                        }
                        alt=""
                      />
                    </div>
                    <h3 className="mt-4 font-weight-bold">
                      Healthcare Services
                    </h3>
                  </div>
                </Col>
                <Col className="rmt" lg="4">
                  <div className="text-center">
                    <div className="">
                      <img
                        className="img-fluid iimg"
                        src={
                          require("../../assets/img/industry/i6.jpeg").default
                        }
                        alt=""
                      />
                    </div>
                    <h3 className="mt-4 font-weight-bold">HVAC</h3>
                  </div>
                </Col>
              </Row>
              <Row className="mt-5">
                <Col className="" lg="4">
                  <div className="text-center">
                    <div className="">
                      <img
                        className="img-fluid iimg"
                        src={
                          require("../../assets/img/industry/i7.jpeg").default
                        }
                        alt=""
                      />
                    </div>
                    <h3 className="mt-4 font-weight-bold">Electric</h3>
                  </div>
                </Col>

                <Col lg="4" className="rmt">
                  <div className="text-center">
                    <div className="">
                      <img
                        className="img-fluid iimg"
                        src={
                          require("../../assets/img/industry/i8.jpeg").default
                        }
                        alt=""
                      />
                    </div>
                    <h3 className="mt-4 font-weight-bold">Plumbing</h3>
                  </div>
                </Col>

                <Col lg="4" className="rmt">
                  <div className="text-center">
                    <div className="">
                      <img
                        className="img-fluid iimg"
                        src={
                          require("../../assets/img/industry/i9.jpeg").default
                        }
                        alt=""
                      />
                    </div>
                    <h3 className="mt-4 font-weight-bold">Cleaning Services</h3>
                  </div>
                </Col>
              </Row>

              <Row className="mt-5">
                <Col className="" lg="4">
                  <div className="text-center">
                    <div className="">
                      <img
                        className="img-fluid iimg"
                        src={
                          require("../../assets/img/industry/i10.jpeg").default
                        }
                        alt=""
                      />
                    </div>
                    <h3 className="mt-4 font-weight-bold">Cannabis</h3>
                  </div>
                </Col>
                <Col className="rmt" lg="4">
                  <div className="text-center">
                    <div className="">
                      <img
                        className="img-fluid iimg"
                        src={
                          require("../../assets/img/industry/i11.jpeg").default
                        }
                        alt=""
                      />
                    </div>
                    <h3 className="mt-4 font-weight-bold">
                      Gym/Fitness Centers
                    </h3>
                  </div>
                </Col>
                <Col lg="4" className="rmt">
                  <div className="text-center">
                    <div className="">
                      <img
                        className="img-fluid iimg"
                        src={
                          require("../../assets/img/industry/i12.jpeg").default
                        }
                        alt=""
                      />
                    </div>
                    <h3 className="mt-4 font-weight-bold">Salons &amp; Spas</h3>
                  </div>
                </Col>
              </Row>

              <Row className="mt-5">
                <Col className="" lg="4">
                  <div className="text-center">
                    <div className="">
                      <img
                        className="img-fluid iimg"
                        src={
                          require("../../assets/img/industry/i13.jpeg").default
                        }
                        alt=""
                      />
                    </div>
                    <h3 className="mt-4 font-weight-bold">Hotels</h3>
                  </div>
                </Col>
                <Col className="rmt" lg="4">
                  <div className="text-center">
                    <div className="">
                      <img
                        className="img-fluid iimg"
                        src={
                          require("../../assets/img/industry/i14.jpeg").default
                        }
                        alt=""
                      />
                    </div>
                    <h3 className="mt-4 font-weight-bold">Retail/eCommerce</h3>
                  </div>
                </Col>

                <Col lg="4" className="rmt">
                  <div className="text-center">
                    <div className="">
                      <img
                        className="img-fluid iimg"
                        src={
                          require("../../assets/img/industry/i15.jpeg").default
                        }
                        alt=""
                      />
                    </div>
                    <h3 className="mt-4 font-weight-bold">
                      Educational Services
                    </h3>
                  </div>
                </Col>
              </Row>

              <Row className="mt-5">
                <Col lg="4">
                  <div className="text-center">
                    <div className="">
                      <img
                        className="img-fluid iimg"
                        src={
                          require("../../assets/img/industry/i16.jpeg").default
                        }
                        alt=""
                      />
                    </div>
                    <h3 className="mt-4 font-weight-bold">Leagal Industry</h3>
                  </div>
                </Col>
              </Row>
            </div>
          </Tab>

          <Tab eventKey="Database" title="Restricted Services">
            <div className="">
              <Row className="mt-5">
                <Col lg="4">
                  <div className="text-center">
                    <div className="">
                      <img
                        className="img-fluid iimg"
                        src={
                          require("../../assets/img/industry/i17.jpeg").default
                        }
                        alt=""
                      />
                    </div>
                    <h3 className="mt-4 font-weight-bold">Trucking</h3>
                  </div>
                </Col>
                <Col className="rmt" lg="4">
                  <div className="text-center">
                    <div className="">
                      <img
                        className="img-fluid iimg"
                        src={
                          require("../../assets/img/industry/i18.jpeg").default
                        }
                        alt=""
                      />
                    </div>
                    <h3 className="mt-4 font-weight-bold">Construction</h3>
                  </div>
                </Col>
                <Col className="rmt" lg="4">
                  <div className="text-center">
                    <div className="">
                      <img
                        className="img-fluid iimg"
                        src={
                          require("../../assets/img/industry/i19.jpeg").default
                        }
                        alt=""
                      />
                    </div>
                    <h3 className="mt-4 font-weight-bold">Used Auto Sales</h3>
                  </div>
                </Col>
              </Row>

              <Row className="mt-5">
                <Col lg="4">
                  <div className="text-center">
                    <div className="">
                      <img
                        className="img-fluid iimg"
                        src={
                          require("../../assets/img/industry/i20.jpeg").default
                        }
                        alt=""
                      />
                    </div>
                    <h3 className="mt-4 font-weight-bold">Non-profits</h3>
                  </div>
                </Col>
                <Col className="rmt" lg="4">
                  <div className="text-center">
                    <div className="">
                      <img
                        className="img-fluid iimg"
                        src={
                          require("../../assets/img/industry/i21.jpeg").default
                        }
                        alt=""
                      />
                    </div>
                    <h3 className="mt-4 font-weight-bold">Mortgage Brokers</h3>
                  </div>
                </Col>
              </Row>
            </div>
          </Tab>
        </Tabs>
      </Container>

      {/* <Container className="mmt my-5">
        <div className="py-5">
          <Row>
            <Col className="" lg="4">
              <div class="text-center">
                <div className="">
                  <img
                    className="img-fluid industry-image"
                    src={require("../../assets/img/industry/i1.jpeg").default}
                    alt=""
                  />
                </div>
                <div class="mt-4">
                  <div className="h4 font-weight-bold">Doctors</div>
                </div>
              </div>
            </Col>
            <Col className="rmt" lg="4">
              <div class="text-center">
                <div className="">
                  <img
                    className="img-fluid industry-image"
                    src={require("../../assets/img/industry/i1.jpeg").default}
                    alt=""
                  />
                </div>
                <div class="mt-4">
                  <div className="h4 font-weight-bold">Doctors</div>
                </div>
              </div>
            </Col>
            <Col className="rmt" lg="4">
              <div class="text-center">
                <div className="">
                  <img
                    className="img-fluid industry-image"
                    src={require("../../assets/img/industry/i1.jpeg").default}
                    alt=""
                  />
                </div>
                <div class="mt-4">
                  <div className="h4 font-weight-bold">Doctors</div>
                </div>
              </div>
            </Col>
          </Row>

          <Row className="mt-5">
            <Col className="" lg="4">
              <div class="text-center">
                <div className="">
                  <img
                    className="img-fluid industry-image"
                    src={require("../../assets/img/industry/i1.jpeg").default}
                    alt=""
                  />
                </div>
                <div class="mt-4">
                  <div className="h4 font-weight-bold">Doctors</div>
                </div>
              </div>
            </Col>
            <Col className="rmt" lg="4">
              <div class="text-center">
                <div className="">
                  <img
                    className="img-fluid industry-image"
                    src={require("../../assets/img/industry/i1.jpeg").default}
                    alt=""
                  />
                </div>
                <div class="mt-4">
                  <div className="h4 font-weight-bold">Doctors</div>
                </div>
              </div>
            </Col>
            <Col className="rmt" lg="4">
              <div class="text-center">
                <div className="">
                  <img
                    className="img-fluid industry-image"
                    src={require("../../assets/img/industry/i1.jpeg").default}
                    alt=""
                  />
                </div>
                <div class="mt-4">
                  <div className="h4 font-weight-bold">Doctors</div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Container> */}

      <Footer />
    </div>
  );
}

export default Industries;
