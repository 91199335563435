import React from "react";
import Footer from "../../components/footer/Footer";
import { Container, Row, Col } from "react-bootstrap";
import { BsSpeedometer2, BsLightbulb } from "react-icons/bs";
import { VscWorkspaceTrusted } from "react-icons/vsc";
import { AiOutlineDollarCircle, AiOutlinePhone } from "react-icons/ai";

function Partners() {
  return (
    <div className="">
      <div className="partner-showcase mmt">
        <Container className=" d-flex align-items-center justify-content-center h-100">
          <Container className=" d-flex justify-content-center">
            <div className="showcasecard">
              <div className="">
                <h2 className="font-weight-bold text-center">
                  {" "}
                  How can we help!{" "}
                </h2>
              </div>
              <div className="text-center mt-4">
                <a
                  style={{
                    textDecoration: "none",
                    border: "none",
                    color: "#000",
                  }}
                  className="btn btn-primary font-weight-bold mx-4"
                  href="https://docs.google.com/forms/d/e/1FAIpQLScDOUd1oBSAQbtDJc1B3pzr_bYY4tmeaUpO4O34I9CDWN8Tig/viewform?vc=0&c=0&w=1&flr=0"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Become a Partner
                </a>
              </div>
            </div>
          </Container>
        </Container>
        {/* <Container className="d-flex align-items-center justify-content-center h-100">
          <div className="">
            <div className="">
              <h2 className="text-light font-weight-bold text-center">
                
              </h2>
            </div>
          </div>
        </Container> */}
      </div>

      <Container className="mmt-5">
        <Row>
          <Col lg="6">
            <div className="">
              <img
                className="img-fluid"
                style={{ borderRadius: "1rem" }}
                src={require("../../assets/img/pp.png").default}
                alt=""
              />
            </div>
          </Col>
          <Col className="d-flex align-items-center rmt" lg="6">
            <div>
              <div className="">
                <h2 className="font-weight-bold">
                  Why You Should Choose C2 Advance
                </h2>
              </div>
              <p className="mt-5 subtitle font-weight-bold">
                If you own or you know of a business that has struggled in the
                past to get approval for funding, this may be a fast and easy
                way to get the cash required to run the business. Whether you
                are a startup or have been in business for many years, C2
                Advance has the right financing plan for businesses of all sizes
                and types.
              </p>
            </div>
          </Col>
        </Row>
      </Container>

      <Container className="info1 mmt-5 py-5">
        <div className="text-center">
          <h1 style={{ fontSize: "2rem" }} className="font-weight-bold">
            Get the alternative financing you need to run the business you want
          </h1>
          <h1
            style={{ fontSize: "3rem" }}
            className="text-primary font-weight-bold"
          >
            Why Partner With Us
          </h1>
        </div>

        <Row className="mmt-5">
          <Col lg="6">
            <div className="">
              <div className="d-flex">
                <div className="">
                  <BsSpeedometer2 className="text-primary" size={30} />
                </div>
                <div className="h4 font-weight-bold mx-3">
                  Extremely Fast Turnaround
                </div>
              </div>
              <p className="subtitle">
                As industry veterans, we know that speed is extremely important
                to ISO’s. That’s why we’ve carefully honed our processes so that
                we can send you an approval in less than 24 hours. Our existing
                ISO’s have gotten used to our speed, so should you. Test us!
                Send us your files and you will be pleasantly surprised with our
                unparalleled approval turnaround.
              </p>
            </div>
          </Col>
          <Col lg="6" className="rmt">
            <div className="">
              <div className="d-flex">
                <div className="">
                  <VscWorkspaceTrusted className="text-primary" size={30} />
                </div>
                <div className="h4 font-weight-bold mx-3">
                  Universally Trusted
                </div>
              </div>
              <p className="subtitle">
                Our reputation remains the gold standard in the Merchant Cash
                Advance industry. We will never backdoor your deals. We
                carefully vet our employees and we audit our team regularly to
                make sure our security is top-quality. Trust is essential for a
                successful working relationship with a funder, that’s why we’ve
                made it our #1 focus from the day we launched this company.
              </p>
            </div>
          </Col>
        </Row>

        <Row className="mmt-5">
          <Col lg="6">
            <div className="">
              <div className="d-flex">
                <div className="">
                  <BsLightbulb className="text-primary" size={30} />
                </div>
                <div className="h4 font-weight-bold mx-3">
                  Optimum Solutions
                </div>
              </div>
              <p className="subtitle">
                UFS has funding programs for business owners with bad credit
                history, newer businesses and those businesses that are
                performing well but their financial statements don’t do them
                justice. We approve a large percentage of our applications and
                can provide our merchants significantly more capital than most
                in the industry. It only takes a few minutes to apply and less
                than 24 hours for approval.
              </p>
            </div>
          </Col>
          <Col lg="6" className="rmt">
            <div className="">
              <div className="d-flex">
                <div className="">
                  <AiOutlineDollarCircle className="text-primary" size={30} />
                </div>
                <div className="h4 font-weight-bold mx-3">High Commissions</div>
              </div>
              <p className="subtitle">
                We pay our ISO’s one of the highest commissions in the industry.
                We also frequently run special bonus programs for ISO’s that
                close a nice amount of deals with us. ISO’s work incredibly hard
                to get their merchant’s funding; UFS shows its appreciation by
                compensating our ISO’s properly.
              </p>
            </div>
          </Col>
        </Row>

        <Row className="mmt-5">
          <Col lg="6">
            <div className="">
              <div className="d-flex">
                <div className="">
                  <AiOutlinePhone className="text-primary" size={30} />
                </div>
                <div className="h4 font-weight-bold mx-3">Customer Service</div>
              </div>
              <p className="subtitle">
                Every business is unique, and each situation needs careful
                review. We make sure to work closely with our ISO’s so that each
                submission gets its proper attention. Our ISO’s and our
                merchants appreciate our thorough review and understanding of
                each business. We stay in touch with our ISO’s and merchants all
                throughout the lifecycle of our relationship.
              </p>
            </div>
          </Col>
        </Row>
      </Container>

      <Footer />
    </div>
  );
}

export default Partners;
