import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "./pages/home/Home";
import Navbar from "./components/navbar/Navbar";
import Partners from "./pages/partners/Partners";
import Industries from "./pages/industries/Industries";

function App() {
  return (
    <>
      <Router>
        <Navbar />
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route exact path="/partners">
            <Partners />
          </Route>
          <Route exact path="/industries">
            <Industries />
          </Route>
        </Switch>
      </Router>
    </>
  );
}

export default App;
